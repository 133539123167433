<template>
  <div class="centered">
    <b>Error 404</b><br>Page not found
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        return {
        }

    }
};
</script>

<style scoped>
  .centered {
    font-size:1.2em;
    position: fixed; /* or absolute */
    top: 40%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    line-height: 1.2em;
  }
</style>
